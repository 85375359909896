import jwtEncode from 'jwt-encode'

import { OptionModel } from 'app/hooks/useOptions'
import {
  PAYMENT_CONNECT_STATUS,
  PAYMENT_METHOD
} from 'app/utils/constants/payment.constants'

import {
  CI_ENV,
  IS_NATIVE_APP,
  JWT_CAPTCHA_SECRET_KEY,
  WEBSITE_URL
} from '../constants/env.constants'
import { MAKE_SOURCE_TYPE } from '../constants/listing.constants'

export const deepMerge = ({ a = {}, b = {}, fn }) =>
  [...new Set([...Object.keys(a), ...Object.keys(b)])].reduce(
    (acc, key) => ({ ...acc, [key]: fn(a[key], b[key]) }),
    {}
  )

export const verificationValuesDehydrated = (a = [], b = []) => [...a, ...b]

export const pluralize = (val, word, plural = word + 's') => {
  const _pluralize = (num, word, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural
  if (typeof val === 'object')
    return (num, word) => _pluralize(num, word, val[word])
  return _pluralize(val, word, plural)
}

export const dateDifferenceToToday = (valueDate: string): number => {
  const dateTime = new Date(valueDate).getTime()
  const today = new Date().setHours(0, 0, 0, 0)
  const date = new Date(dateTime).setHours(0, 0, 0, 0)

  return Math.floor((date - today) / (3600 * 1000))
}

export const moreThanQuantity = (value, quantity = 9999) => {
  const isMoreThan = value > quantity

  return { value: isMoreThan ? `${quantity}+` : value, isMoreThan }
}

export const getLastUrlSegment = (path: string): string =>
  path.substring(path.lastIndexOf('/') + 1)

export const hasActivePaymentGateway = (
  paymentGateways: PaymentGatewayModel[]
) => paymentGateways.some((el) => el.active)

export const getIsWaitConnectPaypal = (
  paymentGateways: PaymentGatewayModel[]
) =>
  paymentGateways.some((el) => {
    const isPayPal = el.name === PAYMENT_METHOD.paypal
    const isWaitingCompletion =
      el.status === PAYMENT_CONNECT_STATUS.waiting_for_connect_completion &&
      !!el?.merchant_id_added

    return isPayPal && isWaitingCompletion
  })

export const rIdleCallback =
  typeof requestIdleCallback === 'function' ? requestIdleCallback : setTimeout

export const getFirstUrlAttachment = (
  attachments: Attachment[]
): string | undefined =>
  attachments?.find((attachment) => attachment?.position === 1)?.url

interface CheckUniversalMakeOptionProps {
  selectedMakes?: string[]
  allMakes?: Make[]
  isDisabled?: boolean
}

export const checkUniversalMakeOption = ({
  selectedMakes,
  allMakes,
  isDisabled
}: CheckUniversalMakeOptionProps) => {
  const isEmptySelectedMakes = !!selectedMakes && selectedMakes?.length === 0

  if (isEmptySelectedMakes || isDisabled) {
    return { universalMakeOption: null, isUniversalMakeOption: false }
  }

  const universalMakeOption = allMakes?.find(
    (item) =>
      selectedMakes?.includes(item.name) &&
      item.source === MAKE_SOURCE_TYPE.system
  )

  return { universalMakeOption, isUniversalMakeOption: !!universalMakeOption }
}

export const transformOptionsToMakes = (options: OptionModel[]): Make[] =>
  options.map((item) => ({
    id: String(item.id),
    name: String(item.label),
    source: String(item.source)
  }))

export const sortAlphabetically = (a: string, b: string) => a.localeCompare(b)

export const convertPdfFileToUrl = async (file: string): Promise<string> => {
  const base64Response = await fetch(`data:application/pdf;base64,${file}`)
  const blob = await base64Response.blob()
  const fileURL = URL.createObjectURL(blob)

  return fileURL
}

export const getExpiredTimeInSeconds = (minutes = 2): number => {
  const currentSecondsTime = Date.now() / 1000
  const expiredTime = currentSecondsTime + minutes * 60

  return expiredTime
}

export const generateAgentToken = (): string => {
  const data = {
    agent: IS_NATIVE_APP ? 'mobile' : 'web',
    exp: getExpiredTimeInSeconds()
  }

  return jwtEncode(data, JWT_CAPTCHA_SECRET_KEY)
}

export const isValidInternalRequest = ({
  secret,
  origin,
  referer
}: {
  secret?: string
  origin?: string
  referer?: string
}) => {
  const isValidRequest =
    origin === WEBSITE_URL || (referer && referer.startsWith(WEBSITE_URL ?? ''))
  const isValidSecret = secret === process.env.INTERNAL_API_SECRET_TOKEN

  if (isValidRequest) return true

  return isValidSecret
}

export const isStripePaymentActive = (paymentGateways: PaymentGatewayModel[]) =>
  paymentGateways.some((el) => el.active && el.name === PAYMENT_METHOD.stripe)

export const isPaypalPaymentActive = (paymentGateways: PaymentGatewayModel[]) =>
  paymentGateways.some((el) => el.active && el.name === PAYMENT_METHOD.paypal)

export const hasAllPaymentActive = (paymentGateways: PaymentGatewayModel[]) =>
  paymentGateways.every((el) => el.active)

export const getStaticImageSrc = (image: {
  src: string
  height: number
  width: number
  blurDataURL?: string
}) => {
  const isDevelopEnv = CI_ENV !== 'production' && CI_ENV !== 'staging'
  const isDevelop = process.env.NODE_ENV === 'development' || isDevelopEnv

  const imageSrc = isDevelop ? image : `${WEBSITE_URL}${image.src}`

  return imageSrc
}
