import { nextGoogleGeocode } from '../NextApi/google'
import { nextLoqateAutocomplete, nextLoqateDetails } from '../NextApi/loqate'

import { AddressObject, Suggest } from './Geosuggest.types'
import { getGoogleAddressObject, getLocation } from './Geosuggest.utils'

class Geosuggest {
  /**
   * The autocomplete service to get suggests
   */
  private autocompleteService = nextLoqateAutocomplete

  /**
   * The geocoder to get geocoded results
   */
  private geocoder = nextGoogleGeocode

  /**
   * The places service to get place details
   */
  private placesService = nextLoqateDetails

  getDetails(suggestToGeocode: Suggest): Promise<Suggest> {
    const options = {
      Id: suggestToGeocode.loqateId ?? '',
      Field1Format: '{Latitude}',
      Field2Format: '{Longitude}'
    }

    return new Promise((resolve, reject) => {
      this.placesService(options).then((resp) => {
        const address = resp?.Items?.[0]

        if (!address?.Id) reject(resp)

        const suggest = {
          ...suggestToGeocode,
          loqateAddress: address,
          location: getLocation({
            lat: Number(address?.Field1),
            lng: Number(address?.Field2)
          })
        }

        resolve(suggest as Suggest)
      })
    })
  }

  reverseGeocode(latlng: {
    lat: number
    lng: number
  }): Promise<AddressObject> | null {
    if (!this.geocoder) return null

    const latLngString = `${latlng.lat},${latlng.lng}`

    return new Promise((resolve, reject) => {
      this.geocoder({ latlng: latLngString }).then((resp) => {
        if (resp?.status === 'OK') {
          const gmaps = resp?.results[0]
          if (gmaps?.address_components) {
            const address = getGoogleAddressObject({
              addressComponents: gmaps?.address_components
            })

            resolve(address)
          } else reject(resp)
        } else reject(resp)
      })
    })
  }

  searchSuggests(
    input: string,
    containerId?: string
  ): Promise<Suggest[]> | null {
    if (!this.autocompleteService || !input) return null

    const options = {
      Text: input,
      Countries: 'GB',
      Language: 'en',
      ...(!!containerId && { Container: containerId })
    }

    return new Promise((resolve, reject) => {
      this.autocompleteService(options).then((result) => {
        if (!result.Items.length) reject(result)

        const suggests: Suggest[] = []

        result?.Items?.forEach((suggest) => {
          suggests.push({
            description: suggest.Description,
            label: suggest?.Text,
            loqateId: suggest.Id,
            loqateType: suggest.Type
          })
        })

        resolve(suggests)
      })
    })
  }
}

export default Geosuggest
