import { AddressObject, Suggest, SuggestOptions } from './Geosuggest.types'

export const getLocation = (
  location: google.maps.LatLng | google.maps.LatLngLiteral | undefined
): google.maps.LatLngLiteral | {} =>
  location
    ? {
        lat: typeof location.lat !== 'function' ? location.lat : location.lat(),
        lng: typeof location.lng !== 'function' ? location.lng : location.lng()
      }
    : {}

export const getSuggestOptions = (suggestOptions: SuggestOptions) => {
  const options: SuggestOptions = {}

  const { location, radius, bounds, types, country } = suggestOptions

  if (location) options.location = location
  if (radius) options.radius = Number(radius)
  if (bounds) options.bounds = bounds
  if (types) options.types = types
  if (country) options.componentRestrictions = { country }

  return options
}

interface GetGoogleAddressObjectProps {
  addressComponents?: google.maps.GeocoderAddressComponent[]
  name?: string
}

export const getGoogleAddressObject = ({
  addressComponents,
  name
}: GetGoogleAddressObjectProps): AddressObject => {
  const shouldBeComponent = {
    street_number: ['street_number'],
    subpremise: ['subpremise'],
    premise: ['premise'],
    postal_code: ['postal_code_prefix', 'postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
      'postal_town',
      'political'
    ],
    country: ['country'],
    full_country_name: ['country'],
    state: ['administrative_area_level_2'],
    company_name: ['name']
  }

  const address = {
    street_number: '',
    postal_code: '',
    street: '',
    region: '',
    city: '',
    country: '',
    full_country_name: '',
    subpremise: '',
    premise: '',
    state: '',
    company_name: '',
    sub_building: ''
  }

  addressComponents?.forEach((component) => {
    for (const shouldBe in shouldBeComponent) {
      if (shouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country' || shouldBe === 'region') {
          address[shouldBe] = component.short_name
        } else {
          address[shouldBe] = component.long_name
        }
      }
    }
  })

  const addressObject = {
    ...address,
    ...(!!name && { company_name: name })
  }

  return addressObject
}

interface GetAddressObjectProps {
  addressObject?: Suggest['loqateAddress']
}

export const getAddressObject = ({
  addressObject
}: GetAddressObjectProps): AddressObject => {
  const address = {
    street_number: addressObject?.BuildingNumber ?? '',
    postal_code: addressObject?.PostalCode ?? '',
    street: addressObject?.Street ?? '',
    region: addressObject?.AdminAreaName ?? '',
    city: addressObject?.City ?? '',
    country: addressObject?.CountryIso2 ?? '',
    full_country_name: addressObject?.CountryName ?? '',
    subpremise: '',
    premise: '',
    state: addressObject?.ProvinceName ?? addressObject?.Province ?? '',
    company_name: addressObject?.Company ?? '',
    sub_building: addressObject?.SubBuilding ?? '',
    loqate_place_id: addressObject?.Id ?? ''
  }

  return address
}

export const isSuggestPlaceResult = (
  result?: Suggest['gmaps']
): result is google.maps.places.PlaceResult => !!result && 'place_id' in result

const allowedTypes = [
  'street_address',
  'premise',
  'car_dealer',
  'car_rental',
  'car_repair',
  'car_wash',
  'establishment',
  'subpremise'
]

export const isAllowedSuggestType = (
  suggest: google.maps.places.AutocompletePrediction
): boolean => suggest.types.some((type) => allowedTypes.includes(type))

export const formatSelectedGeoSuggestLabel = (address: AddressObject) => {
  const baseAddress = `${address?.street} ${address?.city} ${address?.postal_code}`

  const streetAddress = !!address?.street_number
    ? `${address?.street_number} ${baseAddress}`
    : baseAddress
  const subBuildingAddress = !!address?.sub_building
    ? `${address?.sub_building}, ${streetAddress}`
    : streetAddress

  const addressLabel = !!address?.company_name
    ? `${address?.company_name}, ${subBuildingAddress}`
    : subBuildingAddress

  return addressLabel
}
