import { useRouter } from 'next/router'
import React, { useCallback, useEffect } from 'react'

import { IntlProvider } from 'react-intl'
import { shallow } from 'zustand/shallow'

import locales from 'app/i18n/next/locales'
import LocalStorage from 'app/services/Storage'
import useAppStore from 'app/store/app.store'
import { STORAGE_KEYS } from 'app/utils/constants/storage.constants'

import { ReactIntlProviderProps } from './types'

const ReactIntlProvider: RFC<ReactIntlProviderProps> = ({
  children,
  locale: controlledLocale
}) => {
  const {
    locale = '',
    defaultLocale,
    push,
    pathname,
    asPath,
    query
  } = useRouter()

  // eslint-disable-next-line import/namespace
  const messages = locales[controlledLocale || locale]

  // update storage on store change
  const afterChangeStore = useCallback(
    ({ locale, currency }, previousValue) => {
      if (previousValue.locale && previousValue.locale !== locale)
        push({ pathname, query }, asPath, { locale })

      LocalStorage.setItem(STORAGE_KEYS.currency, currency)
    },
    [pathname, asPath]
  )

  // subscribe to store change
  useEffect(() => {
    const unsubStore = useAppStore.subscribe(
      (store) => ({
        locale: store.locale,
        currency: store.currency,
        isAcceptedCookies: store.isAcceptedCookies
      }),
      afterChangeStore,
      { equalityFn: shallow }
    )

    return () => unsubStore()
  }, [afterChangeStore])

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      defaultLocale={defaultLocale}
    >
      <>{children}</>
    </IntlProvider>
  )
}

export default ReactIntlProvider
