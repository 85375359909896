import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect } from 'react'

import * as gtag from '~/utils/helpers/gtag.helpers'

const GTMScript = () => {
  const router = useRouter()

  useEffect(() => {
    if (router?.events) {
      router.events.on('routeChangeComplete', gtag.pageview)
      return () => {
        router.events.off('routeChangeComplete', gtag.pageview)
      }
    }
  }, [router?.events])

  return (
    <>
      <Script
        id="next-gtm"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied'
            });

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${gtag.GTM_ID}');
          `
        }}
      />
    </>
  )
}

export default GTMScript
