import { useEffect } from 'react'

import LocalStorage from 'app/services/Storage'
import useAppStore from 'app/store/app.store'
import { CI_ENV } from 'app/utils/constants/env.constants'
import { STORAGE_KEYS } from 'app/utils/constants/storage.constants'

import { GTM_ID } from '~/utils/helpers/gtag.helpers'

const useUserCookies = () => {
  const { changeAcceptedCookies } = useAppStore((state) => ({
    changeAcceptedCookies: state.changeAcceptedCookies
  }))
  const isEnabledCookiesYes = CI_ENV === 'production' && GTM_ID

  const setAcceptedCookies = (value: boolean) => {
    if (!value) LocalStorage.removeItem(STORAGE_KEYS.youRecentlyViewed)

    changeAcceptedCookies(value)
  }

  const checkInitialAdvertisementConsent = () => {
    if (window.cookieyes?._ckyConsentStore) {
      const advertisementConsent =
        window.cookieyes._ckyConsentStore.get('advertisement')

      const isAcceptedAdvertisement = advertisementConsent === 'yes'

      setAcceptedCookies(isAcceptedAdvertisement)
    }
  }

  useEffect(() => {
    if (!isEnabledCookiesYes) return

    const updateConsent = (eventData) => {
      if (eventData.detail.accepted?.length > 0) {
        const isAcceptedAdvertisement =
          eventData.detail.accepted.includes('advertisement')

        setAcceptedCookies(isAcceptedAdvertisement)
      }
    }

    setTimeout(() => checkInitialAdvertisementConsent(), 500)

    document.addEventListener('cookieyes_consent_update', updateConsent)

    return () => {
      document.removeEventListener('cookieyes_consent_update', updateConsent)
    }
  }, [])
}

export default useUserCookies
