import { subscribeWithSelector } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import type { AddressObject } from 'app/services/Geosuggest/Geosuggest.types'
import { CI_ENV, IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import {
  defaultCurrency,
  defaultLocale
} from 'app/utils/constants/intl.constants'

const getDefaultCurrency = () => {
  if (typeof localStorage !== 'undefined' && localStorage.currency)
    return localStorage.currency

  return defaultCurrency
}

const getDefaultCookies = () => {
  if (IS_NATIVE_APP) return true

  const isProduction = CI_ENV === 'production'

  return isProduction
}

type ElasticClearState = () => void

export interface AppStore {
  initialized: boolean
  visitedWelcomeScreen: boolean
  locale: string
  currency: string
  isAcceptedCookies: boolean
  scrollEnabled: boolean
  initialPromises: Promise<any>[]
  loadedGoogleMapsApi: boolean
  location: { latitude?: number; longitude?: number }
  address: AddressObject | null
  isTablet: boolean
  elasticClearState: ElasticClearState
  initPromise: (promise: Promise<any>) => void
  initializeApp: () => void
  visitWelcomeScreen: (visit: boolean) => void
  updateScrollEnabled: (value: boolean) => void
  changeCurrency: (currency: string) => void
  changeAcceptedCookies: (value: boolean) => void
  changeLocale: (locale: string) => void
  updateStore: (store: Partial<AppStore>) => void
  setLoadedGoogleMapsApi: () => void
  setLocation: (location: { latitude: number; longitude: number }) => void
  setAddress: (address: AddressObject | null) => void
  setIsTablet: (isTablet: boolean) => void
  setElasticClearState: (ElasticClearState) => void
}

const useAppStore = createWithEqualityFn<AppStore>()(
  subscribeWithSelector((set) => ({
    initialized: false,
    visitedWelcomeScreen: false,
    locale: defaultLocale,
    currency: getDefaultCurrency(),
    isAcceptedCookies: getDefaultCookies(),
    scrollEnabled: true,
    initialPromises: [],
    loadedGoogleMapsApi: false,
    location: {},
    address: null,
    isTablet: false,
    elasticClearState: () => {},
    initPromise: (promise) =>
      set(({ initialPromises }) => ({
        initialPromises: [...initialPromises, promise]
      })),
    visitWelcomeScreen: (visit) => set(() => ({ visitedWelcomeScreen: visit })),
    initializeApp: () =>
      set(() => ({ initialized: true, initialPromises: [] })),
    changeLocale: (locale) => set(() => ({ locale: locale })),
    changeCurrency: (currency) => set(() => ({ currency: currency })),
    changeAcceptedCookies: (value) => set(() => ({ isAcceptedCookies: value })),
    updateStore: (store) => set(() => store),
    updateScrollEnabled: (value) => set(() => ({ scrollEnabled: value })),
    setLoadedGoogleMapsApi: () => set(() => ({ loadedGoogleMapsApi: true })),
    setLocation: (location) => set(() => ({ location: location })),
    setAddress: (address) => set(() => ({ address: address })),
    setIsTablet: (isTablet) => set(() => ({ isTablet: isTablet })),
    setElasticClearState: (clearFunction: ElasticClearState) =>
      set(() => ({
        elasticClearState: clearFunction
      }))
  })),
  shallow
)

export default useAppStore
