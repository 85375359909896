declare global {
  interface Window {
    dataLayer: Record<string, any>[]
    cookieyes: {
      _ckyConsentStore: {
        get: (key: string) => string
      }
    }
  }
}

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

export const pageview = (url: string) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url
  })
}
