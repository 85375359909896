import NextApi from 'app/services/NextApi'

import {
  LoqateFindAddressRequest,
  LoqateFindAddressResponse,
  LoqateRetrieveRequest,
  LoqateRetrieveResponse
} from '../Geosuggest/Geosuggest.types'

/**
 * Get Loqate Autocomplete
 */
export const nextLoqateAutocomplete = async (
  options: LoqateFindAddressRequest
): Promise<LoqateFindAddressResponse> =>
  NextApi.get('/loqate/findAddress', { params: options })

/**
 * Get Loqate Address Details
 */
export const nextLoqateDetails = async (
  params: LoqateRetrieveRequest
): Promise<LoqateRetrieveResponse> =>
  NextApi.get('/loqate/retrieveDetails', { params })
